import React from  "react"
import Layout from "../Layout"
import Contact from "../Contact";
import Summary2022 from "../Summary2022"
import Localization from "../Localization"

const IndexPage = ({ lang }) => (
  <Layout lang={lang}>
    <Localization lang={lang} />
    <Contact lang={lang} />
  </Layout>
)

export default IndexPage