import React, { useEffect, useState } from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import * as s from "./style.module.css"
import { LazyLoadImage } from "react-lazy-load-image-component"

export default function CustomModal(modalData) {
  const [openModal, setOpenModal] = useState(true)
  const toggle = () => setOpenModal(!openModal)

  useEffect(() => {
    if (!modalData) return
    setOpenModal(true)
  }, [modalData])

  const {
    modalData: { name, title, description, image, linkedin },
  } = modalData

  let hide = false

  if (name === " " || linkedin === " ") {
    hide = true
  }

  let logo = require("./images/linkedin.svg").default

  if (name === "Kinga Chabros") {
    logo = require("../Mentors/images/fb.svg").default
  }

  if (!openModal) return null

  return (
    <Modal className={s.modal} isOpen={openModal} toggle={toggle} onExit={() => {
      modalData.isModalOpen(false);
      setOpenModal(false);
    }
    }>
      <div className={s.modalClose} onClick={() => {
        modalData.isModalOpen(false);
        setOpenModal(false);
      }} />
      <ModalBody>
        <div className={s.modalContentWrapper}>
          <div className={s.modalImageWrapper}>
            <LazyLoadImage width={"300px"} effect="blur"  alt="mentor zdjecie" src={image}/>
          </div>
          <div className={s.restWrapper}>
            <p
              className={s.modalName}
              dangerouslySetInnerHTML={{ __html: name }}
            />
            <div className={s.titleWithLinkWrapper}>
              <p
                className={s.modalTitle}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              {!hide && (
                <div className={s.modalLinkedin}>
                  <a href={linkedin} target="_blank">
                    <img alt="logo social mediów mentora" src={logo} />
                  </a>
                </div>
              )}
            </div>
            <img
              className={s.modalLine}
              src={require("../Text/icons/line.svg").default}
            />
            <p
              className={s.modalDescription}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
      </ModalBody>
      <img
        className={s.modalSplash}
        src={require("./images/modalSplash.svg").default}
      />
    </Modal>
  )
}
