import React, { useEffect, useState } from "react"
import { Container, Row, Col, Button, Collapse } from "reactstrap"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Dots from "../Dots"
import labels from "./const"
import useLabels from "../../utils/useLabels"
import * as s from "./style.module.css"
import CustomModal from "./modal"
import MentorImage from "../image"
import { LazyLoadImage } from "react-lazy-load-image-component"

export default function Mentors({ lang }) {
  const t = useLabels(labels, lang)
  const [isOpen, setIsOpen] = useState(false)
  const [modal, setModal] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  const isModalOpen = (data) => {
   setModal(data)
  }

  return (
    <div id="mentorzy" className={s.root}>
      <Container className={s.mentorsCards}>
        <div className={s.header}>
          <h2>{t.title}</h2>
          <img
            className={s.line}
            src={require("../Text/icons/line.svg").default}
          />
        </div>
        <Row sm={1} md={2} lg={5}>
          {t.mentors.slice(0, 15).map(mentor => (
            <Col className={s.mentorCard}>
              <Mentor mentor={mentor} setModalData={setModal} />
            </Col>
          ))}
        </Row>
        <Collapse isOpen={isOpen}>
          <Row sm={1} md={2} lg={5}>
            {t.mentors.slice(15, 35).map(mentor => (
              <Col>
                <Mentor mentor={mentor} setModalData={setModal} />
              </Col>
            ))}
          </Row>
        </Collapse>

        {!isOpen && (
          <Button
            color="primary"
            onClick={toggle}
          >Pokaż więcej</Button>
        )}
      </Container>
      {modal && <CustomModal modalData={modal} isModalOpen={isModalOpen} />}
    </div>
  )
}

function Mentor({ mentor, setModalData }) {
  const { id, name, title, image, linkedin } = mentor
  let hide = false

  if (name === " " || linkedin === " ") {
    hide = true
  }

  let logo = require("./images/linkedin.svg").default

  if (name === "Kinga Chabros") {
    logo = require("../Mentors/images/fb.svg").default
  }

  return (
    <div className={s.mentor} id={id} onClick={() => setModalData(mentor)}>
      <div className={s.imageWrapper}>
        <div className={s.circle} />
        {
            // <MentorImage css={s.mentorImg} filename={image}/>
            <LazyLoadImage effect="blur"  alt="mentor zdjecie" src={image}/>
        }
        <div className={s.dotsWrapper}>
          <Dots x={7} y={6} />
        </div>
        {!hide && (
          <div className={s.linkedin}>
            <a href={linkedin} target="_blank">
              <img alt="logo social mediów mentora" src={logo} />
            </a>
          </div>
        )}
      </div>
      <div className={s.name}>{name}</div>
      <div className={s.title}>{title}</div>
    </div>
  )
}

